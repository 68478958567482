svg-icon {
  display: inline-block;
  line-height: 0;
  overflow: hidden;
  vertical-align: middle;
  svg {
    display: inline-block;
    vertical-align: middle;
    line-height: inherit;
    font-size: 1em;
    width: 1em;
    height: auto;
    line-height: 1;
  }
  &:not(.is-colored) {
    * {
      fill: currentColor;
    }
  }
}


body {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.fr-second-toolbar {
  display: none !important;
}


.cdk-overlay-dark-backdrop {
  background-color: #1a2d4c !important;
  opacity: .75 !important;
}

.cdk-global-overlay-wrapper {
  overflow: auto !important;
  pointer-events: visible !important;
}

.fr-wrapper {
  min-height: 300px !important;
  border-bottom-color: #CCCCCC !important;
}

.fr-toolbar {
  border-radius: 0 !important;
  background-color: #fafafa !important;
}

.owl-nav {
  position: absolute;
  top: -65px;
  right: 15px;
}

.owl-prev,
.owl-next {
  font-size: 30px !important;
  color: #000 !important;
  display: inline-block;
  cursor: pointer;
  background-color: unset !important;
  margin-top: 0 !important;
  padding: 0 !important;
}

.owl-prev {
  margin-left: 10px;
}

.owl-dots {
  margin-top: 20px !important;
}

.bg-blue27 {
  background-color: #ebf5ff !important;
}
.size-7 {
  font-size: 9px !important;
}

.ql-toolbar.ql-snow {
  background-color: #FFF !important;
  color: #000 !important;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  background-color: #FFF;
  color: #000 !important;
}

.nb-theme-dark p, .nb-theme-dark .paragraph {
  color: #000 !important;
}

.blue04 {
  color: rgb(77, 97, 130);
}

.ng-invalid:not(form)  {
  border-bottom: 2px solid #bf040b;
}

.content-editor.ng-invalid:not(form){
  border: none;
  .ql-container {
    border-bottom: 2px solid #bf040b !important;
  }
}

.mat-button-disabled {
  opacity: 0.2;
}

.spinner-loading {
  height: 20px;
  width: 20px;
  margin: auto;
}

.mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.mat-datepicker-content .mat-calendar {
  background: #FFF;
  z-index: 5555;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
}
